/*
 * @description: 请求封装
 * @Author: Jay
 * @Date: 2023-02-24 17:26:54
 * @LastEditors: Jay
 * @LastEditTime: 2023-08-01 14:36:42
 */

// 导入axios
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
// 使用element-ui ElMessage做消息提醒  ElLoading加载
import { ElMessage, ElLoading } from "element-plus";
//请求头
import operate from "@/utils/operate"
//vuex
import store from '@/store/index'
//路由
import router from '@/router/index'

//加载配置
let loadingInstance: any,
    requestNum = 0;
const loading = true;

//加载动画
const addLoading = () => {
    // 防止重复弹出
    requestNum++;
    if (requestNum === 1) {
        loadingInstance = ElLoading.service({ fullscreen: true });
    }
}

// 关闭 加载动画
const cancelLoading = () => {
    requestNum--;
    if (requestNum === 0) loadingInstance?.close();
}

// 解決 类型“AxiosResponse<any, any>”上不存在属性“code”
declare module "axios" {
    interface AxiosResponse<T = any> {
        // 请求 data 里的一级参数
        code: number;
        time: string;
        msg: string;
        data: T;
    }
    // export function create(config?: AxiosRequestConfig): AxiosInstance;
}

//请求配置
export const createAxios = (
    config?: AxiosRequestConfig,
): AxiosInstance => {

    const instance = axios.create({
        //请求头
        baseURL: operate.baseUrl(),
        //超时配置
        timeout: 3 * 1000,
        //跨域携带cookie
        withCredentials: true,
        // 自定义配置覆盖基本配置
        ...config,
    })

    // 添加请求拦截器
    instance.interceptors.request.use(
        function (config: any) {
            // console.log("请求拦截器config:", config);

            //加载动画
            if (loading) addLoading();

            // 配置请求头
            config.headers = {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
            
            //从 请求接口那带过来的token
            // const token = config.token
            //判断是否有token 根据自己的需求判断
            // console.log("判断是否有token", operate.isToken())
            if (operate.isToken() != false) {
                //如果要求携带在参数中
                // config.params = Object.assign({}, config.params, token)
                // 如果要求携带在请求头中
                // config.headers = Object.assign({}, config.headers, operate.uploadParameters())
                //放在参数里
                config.data = Object.assign({}, config.data, operate.uploadParameters())
            }
            return config
        },
        function (error) {
            // 请求错误
            return Promise.reject(error)
        }
    )

    // 添加响应拦截器
    instance.interceptors.response.use(
        function (response) {
            // console.log("响应拦截器response:", response);
            // 关闭加载 动画
            if (loading) cancelLoading();
            //是否登录过期
            if (response.data.code == 400 || response.data.code == 401) {
                ElMessage.error("登录过期,请重新登录")
                //清除登录缓存
                store.commit("LOGOUT")
                //返回首页
                setTimeout(() => {
                    router.push("/");
                }, 500);
                return
            }
            //返回参数
            return response.data;
        },
        function (error) {
            // 关闭加载 动画
            if (loading) cancelLoading();
            /***** 接收到异常响应的处理开始 *****/
            if (error && error.response) {
                // 1.公共错误处理
                // 2.根据响应码具体处理
                switch (error.response.status) {
                    case 400:
                        error.message = '错误请求'
                        break;
                    case 401:
                        error.message = '未授权，请重新登录'
                        break;
                    case 403:
                        error.message = '拒绝访问'
                        break;
                    case 404:
                        error.message = '请求错误,未找到该资源'
                        window.location.href = "/NotFound"
                        break;
                    case 405:
                        error.message = '请求方法未允许'
                        break;
                    case 408:
                        error.message = '请求超时'
                        break;
                    case 500:
                        error.message = '服务器端出错'
                        break;
                    case 501:
                        error.message = '网络未实现'
                        break;
                    case 502:
                        error.message = '网络错误'
                        break;
                    case 503:
                        error.message = '服务不可用'
                        break;
                    case 504:
                        error.message = '网络超时'
                        break;
                    case 505:
                        error.message = 'http版本不支持该请求'
                        break;
                    default:
                        error.message = `连接错误${error.response.status}`
                }
            } else {
                // 超时处理
                if (JSON.stringify(error).includes('timeout')) {
                    error.message = '服务器响应超时，请刷新当前页'
                } else {
                    error.message = '连接服务器失败'
                }
            }
            //提示
            ElMessage.error(error.message)
            /***** 处理结束 *****/
            return Promise.resolve(error.response)
        }
    )

    return instance
}
/*
 * @description: 
 * @Author: Jay
 * @Date: 2023-02-24 17:09:09
 * @LastEditors: Jay
 * @LastEditTime: 2023-09-13 09:13:56
 */

// vuex 数据
import store from '../store/index'

//接口地址
const baseUrl = () => {
    if (process.env.NODE_ENV == "development") {
        //开发环境
        return "https://api.haibanghui.com/"
    } else {
        //正式环境
        return "https://api.haibanghui.com/"
    }
}

// 图片上传接口
const uploadImg = () => {
    return baseUrl() + 'api/common/upload'
}

//获取用户token
const isToken = () => {
    if (store.state.Authorization != '') {
        return store.state.Authorization
    }
    return false;
}

//上传请求头 登录验证
const uploadParameters = () => {
    return { 'key': isToken() }
    // return { 'Authori-zation': 'Bearer ' + this.isToken() }
}

//APP 名字
const appName = () => {
    return "海邦汇";
}

/* eslint-disable */
/*
    格式化时间 加上时分秒
    num: 后台时间格式
    type: 'YY-MM-DD' 年月日 ,'HH-MM-SS' 时分秒 ,不传 年月日时分秒
*/
const happenTime = (num: any, type?: string) => {
    let date = new Date(num * 1000);
    //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    let y: any = date.getFullYear();
    let MM: any = date.getMonth() + 1;
    MM = MM < 10 ? ('0' + MM) : MM; //月补0
    let d: any = date.getDate();
    d = d < 10 ? ('0' + d) : d; //天补0
    let h: any = date.getHours();
    h = h < 10 ? ('0' + h) : h; //小时补0
    let m: any = date.getMinutes();
    m = m < 10 ? ('0' + m) : m; //分钟补0
    let s: any = date.getSeconds();
    s = s < 10 ? ('0' + s) : s; //秒补0
    if (type === 'YY-MM-DD') {
        //年月日
        return y + '-' + MM + '-' + d;
    } else if (type === 'HH-MM-SS') {
        //时分秒
        return h + ':' + m + ':' + s;
    } else {
        //全部
        return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
    }
}

//性别
const formSex = (set: String | Number) => {
    const status: Object = {
        "0": "男",
        "1": "女"
    }
    let key: keyof Object;
    for (key in status) {
        //console.log(key, status[key])
        if (key == set) {
            return status[key];
        }
    }
    return '未知'
}
/* eslint-disable no-new */

// 获取本地图片 ?? 拿到的是本地地址
const getAssetsImages = (name: string) => {
    return new URL(`/src/assets/${name}`, import.meta.url).href;
}


// 页面回到顶部（滚动效果）
const handleScroll = () => {
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    console.log(scrollTop, "scrollTop");
    if (scrollTop > 0) {
        const timeTop = setInterval(() => {
            document.documentElement.scrollTop = document.body.scrollTop = scrollTop -= 50; //一次减50往上滑动
            if (scrollTop <= 0) {
                clearInterval(timeTop);
            }
        }, 10); //定时调用函数使其更顺滑
    }
};

// 缓慢移动至目标
const scrollGo = (id: any) => {
    // 获取点击的按钮对应页面的id
    // let PageId: any = document.querySelector(id);
    // 打印出对应页面与窗口的距离
    // console.log(PageId);
    window.scrollTo({
        top: document.querySelector(id).offsetTop,
        behavior: "smooth",
    });
};

export default {
    baseUrl,
    uploadImg,
    uploadParameters,
    isToken,
    appName,
    happenTime,
    formSex,
    getAssetsImages,
    handleScroll,
    scrollGo
}

<!--
 * @description: 
 * @Author: Jay
 * @Date: 2023-07-22 10:08:52
 * @LastEditors: Jay
 * @LastEditTime: 2023-09-08 13:27:33
-->
<template>
  <router-view />
</template>

<style lang="scss">
//自定义
@import "./assets/css/reset.css";
@import "./assets/css/common.css";
@import "./assets/css/custom.css";
@import "./assets/css/icon.css";
</style>

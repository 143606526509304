/*
 * @description: 主要菜单
 * @Author: Jay
 * @Date: 2023-07-13 11:58:30
 * @LastEditors: Jay
 * @LastEditTime: 2023-08-14 15:22:42
 */

import { RouteRecordRaw } from 'vue-router'

export const main: Array<RouteRecordRaw> = [
    {
        path: "/mainMenus",
        name: "mainMenus",
        component: () => import('../views/MainMenus/MainMenus.vue'),
        redirect: "/home",
        children: [
            {
                path: "/home",
                name: "home",
                component: () => import('../views/HomeView/HomeView.vue'),
                meta: {
                    title: "首页",
                    //是否需要登录
                    requireAuth: true
                }
            },
            // 留学客户
            {
                path: "/studyAbroad",
                name: "studyAbroad",
                component: () => import('../views/ClientManage/StudyAbroad/StudyAbroad.vue'),
                meta: {
                    title: "留学客户",
                    //是否需要登录
                    requireAuth: true
                }
            },
            // 付费方案提交
            {
                path: "/paymentPlan",
                name: "paymentPlan",
                component: () => import('../views/ClientManage/StudyAbroad/PaymentPlan.vue'),
                meta: {
                    title: "付费方案提交",
                    //是否需要登录
                    requireAuth: true
                }
            },
            // 缴费客户
            {
                path: "/paymentClient",
                name: "paymentClient",
                component: () => import('../views/ClientManage/PaymentClient/PaymentClient.vue'),
                meta: {
                    title: "缴费客户",
                    //是否需要登录
                    requireAuth: true
                }
            },
            // 推荐学生
            {
                path: "/breakingView",
                name: "breakingView",
                component: () => import('../views/ClientManage/BreakingView/BreakingView.vue'),
                meta: {
                    title: "推荐学生",
                    //是否需要登录
                    requireAuth: true
                }
            },
            // 机构信息
            {
                path: "/organization",
                name: "organization",
                component: () => import('../views/StudyingAbroad/OrganizationView/OrganizationView.vue'),
                meta: {
                    title: "机构信息",
                    //是否需要登录
                    requireAuth: true
                }
            },
            // 案例库
            {
                path: "/caseLibrary",
                name: "caseLibrary",
                component: () => import('../views/StudyingAbroad/CaseLibrary/CaseLibrary.vue'),
                meta: {
                    title: "案例库",
                    //是否需要登录
                    requireAuth: true
                }
            },
            // 案例详情
            {
                path: "/caseDetails",
                name: "caseDetails",
                component: () => import('../views/StudyingAbroad/CaseLibrary/CaseDetails.vue'),
                meta: {
                    title: "案例详情",
                    //是否需要登录
                    requireAuth: true
                }
            },
            // 编辑案例
            {
                path: "/caseEdit",
                name: "caseEdit",
                component: () => import('../views/StudyingAbroad/CaseLibrary/CaseEdit.vue'),
                meta: {
                    title: "编辑案例",
                    //是否需要登录
                    requireAuth: true
                }
            },
            // 缴费状态
            {
                path: "/payStatus",
                name: "payStatus",
                component: () => import('../views/PayManage/PayStatus/PayStatus.vue'),
                meta: {
                    title: "缴费状态",
                    //是否需要登录
                    requireAuth: true
                }
            },
            // 顾问配置
            {
                path: "/configurationAdvisor",
                name: "configurationAdvisor",
                component: () => import('../views/ManagerConsultant/ConfigurationAdvisor/ConfigurationAdvisor.vue'),
                meta: {
                    title: "顾问配置",
                    //是否需要登录
                    requireAuth: true
                }
            },
            // 顾问详情
            {
                path: "/advisorDetails",
                name: "advisorDetails",
                component: () => import('../views/ManagerConsultant/ConfigurationAdvisor/AdvisorDetails.vue'),
                meta: {
                    title: "顾问详情",
                    //是否需要登录
                    requireAuth: true
                }
            },
            // 编辑顾问
            {
                path: "/advisorEdit",
                name: "advisorEdit",
                component: () => import('../views/ManagerConsultant/ConfigurationAdvisor/AdvisorEdit.vue'),
                meta: {
                    title: "编辑顾问",
                    //是否需要登录
                    requireAuth: true
                }
            },
            // 产品购买
            {
                path: "/productPurchase",
                name: "productPurchase",
                component: () => import('../views/RescueProduct/ProductPurchase/ProductPurchase.vue'),
                meta: {
                    title: "产品购买",
                    //是否需要登录
                    requireAuth: true
                }
            },
            // 订单明细
            {
                path: "/orderList",
                name: "orderList",
                component: () => import('../views/RescueProduct/OrderList/OrderList.vue'),
                meta: {
                    title: "订单明细",
                    //是否需要登录
                    requireAuth: true
                }
            },
            // 入账明细
            {
                path: "/revenueDetail",
                name: "revenueDetail",
                component: () => import('../views/RecordedWithdraw/RevenueDetail/RevenueDetail.vue'),
                meta: {
                    title: "入账明细",
                    //是否需要登录
                    requireAuth: true
                }
            },
            // 提现管理
            {
                path: "/withdrawalManage",
                name: "withdrawalManage",
                component: () => import('../views/RecordedWithdraw/WithdrawalManage/WithdrawalManage.vue'),
                meta: {
                    title: "提现管理",
                    //是否需要登录
                    requireAuth: true
                }
            },
        ]
    }
]